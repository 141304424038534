<template>
    <div class="overlay w100 h100 abs">
        <ForgotForm></ForgotForm>
    </div>
</template>
<script>
import ForgotForm from '@/components/auth/ForgotForm'
export default {
    components:{
        ForgotForm
    }
}
</script>